@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400&family=Ubuntu:wght@300&display=swap');

h1 {
  color: white;
  font-size: 12rem;
  font-weight: 400;
  margin: 0;
  font-family: 'Josefin Sans', sans-serif;
  max-width: calc(100vw - 4rem);
}

h2 {
  font-family: 'Ubuntu', sans-serif;
  color: white;
  font-size: 2.8rem;
  font-weight: 300;
}

h3 {
  color: white;
  font-family: 'Ubuntu', sans-serif;
  font-size: 2rem;
}

h4 {
  color: white;
  font-family: 'Ubuntu', sans-serif;
  font-size: 1.6rem;
}

button {
  font-family: 'Ubuntu', sans-serif;
  font-size: 2.5rem;
  font-weight: 300;
  cursor: pointer;
}

.MuiTooltip-tooltip {
  font-size: 1.2rem !important;
}

.link {
  color: white;
  text-decoration: none;
}

p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 1.2rem;
  font-weight: 300;
  line-height: 3rem;
  margin: 0;
  margin-bottom: 1.5rem;
  font-family: 'Ubuntu', sans-serif;
  max-width: calc(100vw - 4rem);
}

.MuiFormLabel-root {
  font-size: 16px !important;
}

.MuiSelect-select.MuiSelect-select {
  font-size: 1.4rem;
}