@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400&family=Ubuntu:wght@300&display=swap);
html {
  font-size: 10px;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(#061F3E, #020C17);
  background-attachment: fixed;
}

button {
  background: transparent;
  border: 2px solid white;
  color: white;
  padding: 1rem 2rem;
  border-radius: .5rem;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #00000000;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(255, 255, 255);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(255, 255, 255);
}

h1 {
  color: white;
  font-size: 12rem;
  font-weight: 400;
  margin: 0;
  font-family: 'Josefin Sans', sans-serif;
  max-width: calc(100vw - 4rem);
}

h2 {
  font-family: 'Ubuntu', sans-serif;
  color: white;
  font-size: 2.8rem;
  font-weight: 300;
}

h3 {
  color: white;
  font-family: 'Ubuntu', sans-serif;
  font-size: 2rem;
}

h4 {
  color: white;
  font-family: 'Ubuntu', sans-serif;
  font-size: 1.6rem;
}

button {
  font-family: 'Ubuntu', sans-serif;
  font-size: 2.5rem;
  font-weight: 300;
  cursor: pointer;
}

.MuiTooltip-tooltip {
  font-size: 1.2rem !important;
}

.link {
  color: white;
  text-decoration: none;
}

p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 1.2rem;
  font-weight: 300;
  line-height: 3rem;
  margin: 0;
  margin-bottom: 1.5rem;
  font-family: 'Ubuntu', sans-serif;
  max-width: calc(100vw - 4rem);
}

.MuiFormLabel-root {
  font-size: 16px !important;
}

.MuiSelect-select.MuiSelect-select {
  font-size: 1.4rem;
}
@-webkit-keyframes hide {
  from {
    opacity: 0;
  }
  to {
    opacity: 0;
  }
}

@keyframes hide {
  from {
    opacity: 0;
  }
  to {
    opacity: 0;
  }
}

@-webkit-keyframes enter-left {
  from {
    -webkit-transform: translateX(-100px);
            transform: translateX(-100px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}

@keyframes enter-left {
  from {
    -webkit-transform: translateX(-100px);
            transform: translateX(-100px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}

@-webkit-keyframes enter-forwards {
  from {
    -webkit-transform: translateZ(-100px);
            transform: translateZ(-100px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}

@keyframes enter-forwards {
  from {
    -webkit-transform: translateZ(-100px);
            transform: translateZ(-100px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}

@-webkit-keyframes enter-up {
  from {
    -webkit-transform: translateY(100px);
            transform: translateY(100px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}

@keyframes enter-up {
  from {
    -webkit-transform: translateY(100px);
            transform: translateY(100px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}

@-webkit-keyframes enter-left-z30 {
  from {
    -webkit-transform: translateZ(30px) translateX(-100px);
            transform: translateZ(30px) translateX(-100px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateZ(30px) translateX(0);
            transform: translateZ(30px) translateX(0);
    opacity: 1;
  }
}

@keyframes enter-left-z30 {
  from {
    -webkit-transform: translateZ(30px) translateX(-100px);
            transform: translateZ(30px) translateX(-100px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateZ(30px) translateX(0);
            transform: translateZ(30px) translateX(0);
    opacity: 1;
  }
}

@-webkit-keyframes enter-left-zn30 {
  from {
    -webkit-transform: translateZ(-30px) translateX(-100px);
            transform: translateZ(-30px) translateX(-100px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateZ(-30px) translateX(0);
            transform: translateZ(-30px) translateX(0);
    opacity: 1;
  }
}

@keyframes enter-left-zn30 {
  from {
    -webkit-transform: translateZ(-30px) translateX(-100px);
            transform: translateZ(-30px) translateX(-100px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateZ(-30px) translateX(0);
            transform: translateZ(-30px) translateX(0);
    opacity: 1;
  }
}

@-webkit-keyframes enter-right-zn30 {
  from {
    -webkit-transform: translateZ(-30px) translateX(100px);
            transform: translateZ(-30px) translateX(100px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateZ(-30px) translateX(0);
            transform: translateZ(-30px) translateX(0);
    opacity: 1;
  }
}

@keyframes enter-right-zn30 {
  from {
    -webkit-transform: translateZ(-30px) translateX(100px);
            transform: translateZ(-30px) translateX(100px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateZ(-30px) translateX(0);
            transform: translateZ(-30px) translateX(0);
    opacity: 1;
  }
}

@-webkit-keyframes rotatez {
  from {
    -webkit-transform: translateZ(0px) rotate(0deg);
            transform: translateZ(0px) rotate(0deg);
  }
  to {
    -webkit-transform: translateZ(0px) rotate(-360deg);
            transform: translateZ(0px) rotate(-360deg);
  }
}

@keyframes rotatez {
  from {
    -webkit-transform: translateZ(0px) rotate(0deg);
            transform: translateZ(0px) rotate(0deg);
  }
  to {
    -webkit-transform: translateZ(0px) rotate(-360deg);
            transform: translateZ(0px) rotate(-360deg);
  }
}

@-webkit-keyframes rotatez-full-z30 {
  from {
    -webkit-transform: translateZ(30px) rotate(0deg);
            transform: translateZ(30px) rotate(0deg);
  }
  to {
    -webkit-transform: translateZ(30px) rotate(360deg);
            transform: translateZ(30px) rotate(360deg);
  }
}

@keyframes rotatez-full-z30 {
  from {
    -webkit-transform: translateZ(30px) rotate(0deg);
            transform: translateZ(30px) rotate(0deg);
  }
  to {
    -webkit-transform: translateZ(30px) rotate(360deg);
            transform: translateZ(30px) rotate(360deg);
  }
}

@-webkit-keyframes rotatez-zn30 {
  from {
    -webkit-transform: translateZ(-30px) rotate(0deg);
            transform: translateZ(-30px) rotate(0deg);
  }
  to {
    -webkit-transform: translateZ(-30px) rotate(360deg);
            transform: translateZ(-30px) rotate(360deg);
  }
}

@keyframes rotatez-zn30 {
  from {
    -webkit-transform: translateZ(-30px) rotate(0deg);
            transform: translateZ(-30px) rotate(0deg);
  }
  to {
    -webkit-transform: translateZ(-30px) rotate(360deg);
            transform: translateZ(-30px) rotate(360deg);
  }
}

@-webkit-keyframes enter-right {
  from {
    -webkit-transform: translateX(100px);
            transform: translateX(100px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}

@keyframes enter-right {
  from {
    -webkit-transform: translateX(100px);
            transform: translateX(100px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}

@-webkit-keyframes slide-up-z0 {
  from {
    -webkit-transform: translateZ(0px) translateY(20px);
            transform: translateZ(0px) translateY(20px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateZ(0px) translateY(0px);
            transform: translateZ(0px) translateY(0px);
    opacity: 1;
  }
}

@keyframes slide-up-z0 {
  from {
    -webkit-transform: translateZ(0px) translateY(20px);
            transform: translateZ(0px) translateY(20px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateZ(0px) translateY(0px);
            transform: translateZ(0px) translateY(0px);
    opacity: 1;
  }
}

@-webkit-keyframes slide-up-zn10 {
  from {
    -webkit-transform: translateZ(-10px) translateY(20px);
            transform: translateZ(-10px) translateY(20px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateZ(-10px) translateY(0px);
            transform: translateZ(-10px) translateY(0px);
    opacity: 1;
  }
}

@keyframes slide-up-zn10 {
  from {
    -webkit-transform: translateZ(-10px) translateY(20px);
            transform: translateZ(-10px) translateY(20px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateZ(-10px) translateY(0px);
            transform: translateZ(-10px) translateY(0px);
    opacity: 1;
  }
}

@-webkit-keyframes slide-down-z30 {
  from {
    -webkit-transform: translateZ(30px) translateY(-20px);
            transform: translateZ(30px) translateY(-20px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateZ(30px) translateY(0px);
            transform: translateZ(30px) translateY(0px);
    opacity: 1;
  }
}

@keyframes slide-down-z30 {
  from {
    -webkit-transform: translateZ(30px) translateY(-20px);
            transform: translateZ(30px) translateY(-20px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateZ(30px) translateY(0px);
            transform: translateZ(30px) translateY(0px);
    opacity: 1;
  }
}

@-webkit-keyframes slide-down-z20 {
  from {
    -webkit-transform: translateZ(20px) translateY(-20px);
            transform: translateZ(20px) translateY(-20px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateZ(20px) translateY(0px);
            transform: translateZ(20px) translateY(0px);
    opacity: 1;
  }
}

@keyframes slide-down-z20 {
  from {
    -webkit-transform: translateZ(20px) translateY(-20px);
            transform: translateZ(20px) translateY(-20px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateZ(20px) translateY(0px);
            transform: translateZ(20px) translateY(0px);
    opacity: 1;
  }
}

@-webkit-keyframes slide-up-zn40 {
  from {
    -webkit-transform: translateZ(-40px) translateY(20px);
            transform: translateZ(-40px) translateY(20px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateZ(-40px) translateY(0px);
            transform: translateZ(-40px) translateY(0px);
    opacity: 1;
  }
}

@keyframes slide-up-zn40 {
  from {
    -webkit-transform: translateZ(-40px) translateY(20px);
            transform: translateZ(-40px) translateY(20px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateZ(-40px) translateY(0px);
            transform: translateZ(-40px) translateY(0px);
    opacity: 1;
  }
}

@-webkit-keyframes slide-down-z10 {
  from {
    -webkit-transform: translateZ(10px) translateY(-20px);
            transform: translateZ(10px) translateY(-20px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateZ(10px) translateY(0px);
            transform: translateZ(10px) translateY(0px);
    opacity: 1;
  }
}

@keyframes slide-down-z10 {
  from {
    -webkit-transform: translateZ(10px) translateY(-20px);
            transform: translateZ(10px) translateY(-20px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateZ(10px) translateY(0px);
            transform: translateZ(10px) translateY(0px);
    opacity: 1;
  }
}

@-webkit-keyframes slide-left-z50 {
  from {
    -webkit-transform: translateZ(50px) translateX(50px);
            transform: translateZ(50px) translateX(50px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateZ(50px) translateX(0px);
            transform: translateZ(50px) translateX(0px);
    opacity: 1;
  }
}

@keyframes slide-left-z50 {
  from {
    -webkit-transform: translateZ(50px) translateX(50px);
            transform: translateZ(50px) translateX(50px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateZ(50px) translateX(0px);
            transform: translateZ(50px) translateX(0px);
    opacity: 1;
  }
}

@-webkit-keyframes slide-right-z50 {
  from {
    -webkit-transform: translateZ(50px) translateX(-50px);
            transform: translateZ(50px) translateX(-50px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateZ(50px) translateX(0px);
            transform: translateZ(50px) translateX(0px);
    opacity: 1;
  }
}

@keyframes slide-right-z50 {
  from {
    -webkit-transform: translateZ(50px) translateX(-50px);
            transform: translateZ(50px) translateX(-50px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateZ(50px) translateX(0px);
            transform: translateZ(50px) translateX(0px);
    opacity: 1;
  }
}

@-webkit-keyframes hide-50-z40-rotate1deg {
  0% {
    -webkit-transform: translateZ(40px) rotate(0deg);
            transform: translateZ(40px) rotate(0deg);
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  51% {
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(40px) rotate(-1deg);
            transform: translateZ(40px) rotate(-1deg);
    opacity: 0;
  }
}

@keyframes hide-50-z40-rotate1deg {
  0% {
    -webkit-transform: translateZ(40px) rotate(0deg);
            transform: translateZ(40px) rotate(0deg);
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  51% {
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(40px) rotate(-1deg);
            transform: translateZ(40px) rotate(-1deg);
    opacity: 0;
  }
}

@-webkit-keyframes hide-50 {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  51% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes hide-50 {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  51% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes hide-50-z20-rotate1deg {
  0% {
    -webkit-transform: translateZ(20px) rotate(0deg);
            transform: translateZ(20px) rotate(0deg);
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  51% {
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(20px) rotate(-1deg);
            transform: translateZ(20px) rotate(-1deg);
    opacity: 0;
  }
}

@keyframes hide-50-z20-rotate1deg {
  0% {
    -webkit-transform: translateZ(20px) rotate(0deg);
            transform: translateZ(20px) rotate(0deg);
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  51% {
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(20px) rotate(-1deg);
            transform: translateZ(20px) rotate(-1deg);
    opacity: 0;
  }
}

@-webkit-keyframes slide-forwards-zn30 {
  from {
    -webkit-transform: translateZ(-200px);
            transform: translateZ(-200px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateZ(-30px);
            transform: translateZ(-30px);
    opacity: 1;
  }
}

@keyframes slide-forwards-zn30 {
  from {
    -webkit-transform: translateZ(-200px);
            transform: translateZ(-200px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateZ(-30px);
            transform: translateZ(-30px);
    opacity: 1;
  }
}

@-webkit-keyframes slide-forwards-zn10 {
  from {
    -webkit-transform: translateZ(-200px);
            transform: translateZ(-200px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateZ(-10px);
            transform: translateZ(-10px);
    opacity: 1;
  }
}

@keyframes slide-forwards-zn10 {
  from {
    -webkit-transform: translateZ(-200px);
            transform: translateZ(-200px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateZ(-10px);
            transform: translateZ(-10px);
    opacity: 1;
  }
}

@-webkit-keyframes slide-forwards-z10 {
  from {
    -webkit-transform: translateZ(-200px);
            transform: translateZ(-200px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateZ(10px);
            transform: translateZ(10px);
    opacity: 1;
  }
}

@keyframes slide-forwards-z10 {
  from {
    -webkit-transform: translateZ(-200px);
            transform: translateZ(-200px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateZ(10px);
            transform: translateZ(10px);
    opacity: 1;
  }
}

@-webkit-keyframes slide-forwards-z20 {
  from {
    -webkit-transform: translateZ(-200px);
            transform: translateZ(-200px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateZ(20px);
            transform: translateZ(20px);
    opacity: 1;
  }
}

@keyframes slide-forwards-z20 {
  from {
    -webkit-transform: translateZ(-200px);
            transform: translateZ(-200px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateZ(20px);
            transform: translateZ(20px);
    opacity: 1;
  }
}

@keyframes slide-forwards-zn10 {
  from {
    -webkit-transform: translateZ(-200px);
            transform: translateZ(-200px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateZ(-10px);
            transform: translateZ(-10px);
    opacity: 1;
  }
}

@-webkit-keyframes slide-forwards-zn40 {
  from {
    -webkit-transform: translateZ(-200px);
            transform: translateZ(-200px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateZ(-40px);
            transform: translateZ(-40px);
    opacity: 1;
  }
}

@keyframes slide-forwards-zn40 {
  from {
    -webkit-transform: translateZ(-200px);
            transform: translateZ(-200px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateZ(-40px);
            transform: translateZ(-40px);
    opacity: 1;
  }
}

@-webkit-keyframes slide-forwards-zn5 {
  from {
    -webkit-transform: translateZ(-200px);
            transform: translateZ(-200px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateZ(-5px);
            transform: translateZ(-5px);
    opacity: 1;
  }
}

@keyframes slide-forwards-zn5 {
  from {
    -webkit-transform: translateZ(-200px);
            transform: translateZ(-200px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateZ(-5px);
            transform: translateZ(-5px);
    opacity: 1;
  }
}

@-webkit-keyframes slide-forwards-zn20 {
  from {
    -webkit-transform: translateZ(-200px);
            transform: translateZ(-200px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateZ(-20px);
            transform: translateZ(-20px);
    opacity: 1;
  }
}

@keyframes slide-forwards-zn20 {
  from {
    -webkit-transform: translateZ(-200px);
            transform: translateZ(-200px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateZ(-20px);
            transform: translateZ(-20px);
    opacity: 1;
  }
}

@-webkit-keyframes slide-forwards-zn50 {
  from {
    -webkit-transform: translateZ(-200px);
            transform: translateZ(-200px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateZ(-50px);
            transform: translateZ(-50px);
    opacity: 1;
  }
}

@keyframes slide-forwards-zn50 {
  from {
    -webkit-transform: translateZ(-200px);
            transform: translateZ(-200px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateZ(-50px);
            transform: translateZ(-50px);
    opacity: 1;
  }
}

@-webkit-keyframes slide-forwards-z30 {
  from {
    -webkit-transform: translateZ(-200px);
            transform: translateZ(-200px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateZ(30px);
            transform: translateZ(30px);
    opacity: 1;
  }
}

@keyframes slide-forwards-z30 {
  from {
    -webkit-transform: translateZ(-200px);
            transform: translateZ(-200px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateZ(30px);
            transform: translateZ(30px);
    opacity: 1;
  }
}

@-webkit-keyframes slide-backwards-z50 {
  from {
    -webkit-transform: translateZ(200px);
            transform: translateZ(200px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateZ(50px);
            transform: translateZ(50px);
    opacity: 1;
  }
}

@keyframes slide-backwards-z50 {
  from {
    -webkit-transform: translateZ(200px);
            transform: translateZ(200px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateZ(50px);
            transform: translateZ(50px);
    opacity: 1;
  }
}

@-webkit-keyframes slide-backwards-zn30 {
  from {
    -webkit-transform: translateZ(100px);
            transform: translateZ(100px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateZ(-30px);
            transform: translateZ(-30px);
    opacity: 1;
  }
}

@keyframes slide-backwards-zn30 {
  from {
    -webkit-transform: translateZ(100px);
            transform: translateZ(100px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateZ(-30px);
            transform: translateZ(-30px);
    opacity: 1;
  }
}

@-webkit-keyframes slide-backwards-z30 {
  from {
    -webkit-transform: translateZ(100px);
            transform: translateZ(100px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateZ(30px);
            transform: translateZ(30px);
    opacity: 1;
  }
}

@keyframes slide-backwards-z30 {
  from {
    -webkit-transform: translateZ(100px);
            transform: translateZ(100px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateZ(30px);
            transform: translateZ(30px);
    opacity: 1;
  }
}

@-webkit-keyframes hoverx-left-z50 {
  0% {
    -webkit-transform: translateZ(50px) translateX(0px);
            transform: translateZ(50px) translateX(0px);
  }
  50% {
    -webkit-transform: translateZ(50px) translateX(-10px);
            transform: translateZ(50px) translateX(-10px);
  }
  100% {
    -webkit-transform: translateZ(50px) translateX(0px);
            transform: translateZ(50px) translateX(0px);
  }
}

@keyframes hoverx-left-z50 {
  0% {
    -webkit-transform: translateZ(50px) translateX(0px);
            transform: translateZ(50px) translateX(0px);
  }
  50% {
    -webkit-transform: translateZ(50px) translateX(-10px);
            transform: translateZ(50px) translateX(-10px);
  }
  100% {
    -webkit-transform: translateZ(50px) translateX(0px);
            transform: translateZ(50px) translateX(0px);
  }
}

@-webkit-keyframes hoverx-right-z50 {
  0% {
    -webkit-transform: translateZ(50px) translateX(0px);
            transform: translateZ(50px) translateX(0px);
  }
  50% {
    -webkit-transform: translateZ(50px) translateX(10px);
            transform: translateZ(50px) translateX(10px);
  }
  100% {
    -webkit-transform: translateZ(50px) translateX(0px);
            transform: translateZ(50px) translateX(0px);
  }
}

@keyframes hoverx-right-z50 {
  0% {
    -webkit-transform: translateZ(50px) translateX(0px);
            transform: translateZ(50px) translateX(0px);
  }
  50% {
    -webkit-transform: translateZ(50px) translateX(10px);
            transform: translateZ(50px) translateX(10px);
  }
  100% {
    -webkit-transform: translateZ(50px) translateX(0px);
            transform: translateZ(50px) translateX(0px);
  }
}

@-webkit-keyframes hoverz-forwards-zn30 {
  0% {
    -webkit-transform: translateZ(-30px);
            transform: translateZ(-30px);
  }
  50% {
    -webkit-transform: translateZ(-45px);
            transform: translateZ(-45px);
  }
  100% {
    -webkit-transform: translateZ(-30px);
            transform: translateZ(-30px);
  }
}

@keyframes hoverz-forwards-zn30 {
  0% {
    -webkit-transform: translateZ(-30px);
            transform: translateZ(-30px);
  }
  50% {
    -webkit-transform: translateZ(-45px);
            transform: translateZ(-45px);
  }
  100% {
    -webkit-transform: translateZ(-30px);
            transform: translateZ(-30px);
  }
}

@-webkit-keyframes hoverz-backwards-zn30 {
  0% {
    -webkit-transform: translateZ(-30px);
            transform: translateZ(-30px);
  }
  50% {
    -webkit-transform: translateZ(-15px);
            transform: translateZ(-15px);
  }
  100% {
    -webkit-transform: translateZ(-30px);
            transform: translateZ(-30px);
  }
}

@keyframes hoverz-backwards-zn30 {
  0% {
    -webkit-transform: translateZ(-30px);
            transform: translateZ(-30px);
  }
  50% {
    -webkit-transform: translateZ(-15px);
            transform: translateZ(-15px);
  }
  100% {
    -webkit-transform: translateZ(-30px);
            transform: translateZ(-30px);
  }
}

@-webkit-keyframes hoverz-backwards-zn20 {
  0% {
    -webkit-transform: translateZ(-20px);
            transform: translateZ(-20px);
  }
  50% {
    -webkit-transform: translateZ(-10px);
            transform: translateZ(-10px);
  }
  100% {
    -webkit-transform: translateZ(-20px);
            transform: translateZ(-20px);
  }
}

@keyframes hoverz-backwards-zn20 {
  0% {
    -webkit-transform: translateZ(-20px);
            transform: translateZ(-20px);
  }
  50% {
    -webkit-transform: translateZ(-10px);
            transform: translateZ(-10px);
  }
  100% {
    -webkit-transform: translateZ(-20px);
            transform: translateZ(-20px);
  }
}

@-webkit-keyframes hoverz-backwards-z30 {
  0% {
    -webkit-transform: translateZ(30px);
            transform: translateZ(30px);
  }
  50% {
    -webkit-transform: translateZ(45px);
            transform: translateZ(45px);
  }
  100% {
    -webkit-transform: translateZ(30px);
            transform: translateZ(30px);
  }
}

@keyframes hoverz-backwards-z30 {
  0% {
    -webkit-transform: translateZ(30px);
            transform: translateZ(30px);
  }
  50% {
    -webkit-transform: translateZ(45px);
            transform: translateZ(45px);
  }
  100% {
    -webkit-transform: translateZ(30px);
            transform: translateZ(30px);
  }
}

@-webkit-keyframes hoverz-forwards-z30 {
  0% {
    -webkit-transform: translateZ(30px);
            transform: translateZ(30px);
  }
  50% {
    -webkit-transform: translateZ(15px);
            transform: translateZ(15px);
  }
  100% {
    -webkit-transform: translateZ(30px);
            transform: translateZ(30px);
  }
}

@keyframes hoverz-forwards-z30 {
  0% {
    -webkit-transform: translateZ(30px);
            transform: translateZ(30px);
  }
  50% {
    -webkit-transform: translateZ(15px);
            transform: translateZ(15px);
  }
  100% {
    -webkit-transform: translateZ(30px);
            transform: translateZ(30px);
  }
}

@-webkit-keyframes hoverz-forwardslight-z30 {
  0% {
    -webkit-transform: translateZ(30px);
            transform: translateZ(30px);
  }
  50% {
    -webkit-transform: translateZ(35px);
            transform: translateZ(35px);
  }
  100% {
    -webkit-transform: translateZ(30px);
            transform: translateZ(30px);
  }
}

@keyframes hoverz-forwardslight-z30 {
  0% {
    -webkit-transform: translateZ(30px);
            transform: translateZ(30px);
  }
  50% {
    -webkit-transform: translateZ(35px);
            transform: translateZ(35px);
  }
  100% {
    -webkit-transform: translateZ(30px);
            transform: translateZ(30px);
  }
}

@-webkit-keyframes hoverz-backwards-z50 {
  0% {
    -webkit-transform: translateZ(50px);
            transform: translateZ(50px);
  }
  50% {
    -webkit-transform: translateZ(-5px);
            transform: translateZ(-5px);
  }
  100% {
    -webkit-transform: translateZ(50px);
            transform: translateZ(50px);
  }
}

@keyframes hoverz-backwards-z50 {
  0% {
    -webkit-transform: translateZ(50px);
            transform: translateZ(50px);
  }
  50% {
    -webkit-transform: translateZ(-5px);
            transform: translateZ(-5px);
  }
  100% {
    -webkit-transform: translateZ(50px);
            transform: translateZ(50px);
  }
}

@-webkit-keyframes hoverz-forwards-z50 {
  0% {
    -webkit-transform: translateZ(50px);
            transform: translateZ(50px);
  }
  50% {
    -webkit-transform: translateZ(60px);
            transform: translateZ(60px);
  }
  100% {
    -webkit-transform: translateZ(50px);
            transform: translateZ(50px);
  }
}

@keyframes hoverz-forwards-z50 {
  0% {
    -webkit-transform: translateZ(50px);
            transform: translateZ(50px);
  }
  50% {
    -webkit-transform: translateZ(60px);
            transform: translateZ(60px);
  }
  100% {
    -webkit-transform: translateZ(50px);
            transform: translateZ(50px);
  }
}

@-webkit-keyframes hoverz-forwards-zn40 {
  0% {
    -webkit-transform: translateZ(-40px);
            transform: translateZ(-40px);
  }
  50% {
    -webkit-transform: translateZ(-30px);
            transform: translateZ(-30px);
  }
  100% {
    -webkit-transform: translateZ(-40px);
            transform: translateZ(-40px);
  }
}

@keyframes hoverz-forwards-zn40 {
  0% {
    -webkit-transform: translateZ(-40px);
            transform: translateZ(-40px);
  }
  50% {
    -webkit-transform: translateZ(-30px);
            transform: translateZ(-30px);
  }
  100% {
    -webkit-transform: translateZ(-40px);
            transform: translateZ(-40px);
  }
}


@-webkit-keyframes hoverz-forwards-zn10 {
  0% {
    -webkit-transform: translateZ(-10px);
            transform: translateZ(-10px);
  }
  50% {
    -webkit-transform: translateZ(-20px);
            transform: translateZ(-20px);
  }
  100% {
    -webkit-transform: translateZ(-10px);
            transform: translateZ(-10px);
  }
}


@keyframes hoverz-forwards-zn10 {
  0% {
    -webkit-transform: translateZ(-10px);
            transform: translateZ(-10px);
  }
  50% {
    -webkit-transform: translateZ(-20px);
            transform: translateZ(-20px);
  }
  100% {
    -webkit-transform: translateZ(-10px);
            transform: translateZ(-10px);
  }
}

@-webkit-keyframes hoverz-forwards-z20 {
  0% {
    -webkit-transform: translateZ(20px);
            transform: translateZ(20px);
  }
  50% {
    -webkit-transform: translateZ(30px);
            transform: translateZ(30px);
  }
  100% {
    -webkit-transform: translateZ(20px);
            transform: translateZ(20px);
  }
}

@keyframes hoverz-forwards-z20 {
  0% {
    -webkit-transform: translateZ(20px);
            transform: translateZ(20px);
  }
  50% {
    -webkit-transform: translateZ(30px);
            transform: translateZ(30px);
  }
  100% {
    -webkit-transform: translateZ(20px);
            transform: translateZ(20px);
  }
}

@-webkit-keyframes flash-25 {
  0% {
    opacity: 0;
  }
  35% {
    opacity: 0;
  }
  36% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  45% {
    opacity: 1;
  }
  46% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes flash-25 {
  0% {
    opacity: 0;
  }
  35% {
    opacity: 0;
  }
  36% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  45% {
    opacity: 1;
  }
  46% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes hoverz-forwards-z30 {
  0% {
    -webkit-transform: translateZ(30px);
            transform: translateZ(30px);
  }
  50% {
    -webkit-transform: translateZ(0px);
            transform: translateZ(0px);
  }
  100% {
    -webkit-transform: translateZ(30px);
            transform: translateZ(30px);
  }
}

@-webkit-keyframes hoverz-forwards-z10 {
  0% {
    -webkit-transform: translateZ(10px);
            transform: translateZ(10px);
  }
  50% {
    -webkit-transform: translateZ(20px);
            transform: translateZ(20px);
  }
  100% {
    -webkit-transform: translateZ(10px);
            transform: translateZ(10px);
  }
}

@keyframes hoverz-forwards-z10 {
  0% {
    -webkit-transform: translateZ(10px);
            transform: translateZ(10px);
  }
  50% {
    -webkit-transform: translateZ(20px);
            transform: translateZ(20px);
  }
  100% {
    -webkit-transform: translateZ(10px);
            transform: translateZ(10px);
  }
}

@-webkit-keyframes rotatez-z30 {
  0% {
    -webkit-transform: translateZ(30px) rotate(0deg);
            transform: translateZ(30px) rotate(0deg)
  }
  25% {
    -webkit-transform: translateZ(30px) rotate(1deg);
            transform: translateZ(30px) rotate(1deg)
  }
  50% {
    -webkit-transform: translateZ(30px) rotate(0deg);
            transform: translateZ(30px) rotate(0deg)
  }
  75% {
    -webkit-transform: translateZ(30px) rotate(-1deg);
            transform: translateZ(30px) rotate(-1deg)
  }
  100% {
    -webkit-transform: translateZ(30px) rotate(0deg);
            transform: translateZ(30px) rotate(0deg)
  }
}

@keyframes rotatez-z30 {
  0% {
    -webkit-transform: translateZ(30px) rotate(0deg);
            transform: translateZ(30px) rotate(0deg)
  }
  25% {
    -webkit-transform: translateZ(30px) rotate(1deg);
            transform: translateZ(30px) rotate(1deg)
  }
  50% {
    -webkit-transform: translateZ(30px) rotate(0deg);
            transform: translateZ(30px) rotate(0deg)
  }
  75% {
    -webkit-transform: translateZ(30px) rotate(-1deg);
            transform: translateZ(30px) rotate(-1deg)
  }
  100% {
    -webkit-transform: translateZ(30px) rotate(0deg);
            transform: translateZ(30px) rotate(0deg)
  }
}

@-webkit-keyframes hovery-zn40 {
  0% {
    -webkit-transform: translateZ(-40px) translateY(0px);
            transform: translateZ(-40px) translateY(0px)
  }
  50% {
    -webkit-transform: translateZ(-40px) translateY(3px);
            transform: translateZ(-40px) translateY(3px)
  }
  100% {
    -webkit-transform: translateZ(-40px) translateY(0px);
            transform: translateZ(-40px) translateY(0px)
  }
}

@keyframes hovery-zn40 {
  0% {
    -webkit-transform: translateZ(-40px) translateY(0px);
            transform: translateZ(-40px) translateY(0px)
  }
  50% {
    -webkit-transform: translateZ(-40px) translateY(3px);
            transform: translateZ(-40px) translateY(3px)
  }
  100% {
    -webkit-transform: translateZ(-40px) translateY(0px);
            transform: translateZ(-40px) translateY(0px)
  }
}

@-webkit-keyframes hovery-z20 {
  0% {
    -webkit-transform: translateZ(20px) translateY(0px);
            transform: translateZ(20px) translateY(0px)
  }
  50% {
    -webkit-transform: translateZ(20px) translateY(5px);
            transform: translateZ(20px) translateY(5px)
  }
  100% {
    -webkit-transform: translateZ(20px) translateY(0px);
            transform: translateZ(20px) translateY(0px)
  }
}

@keyframes hovery-z20 {
  0% {
    -webkit-transform: translateZ(20px) translateY(0px);
            transform: translateZ(20px) translateY(0px)
  }
  50% {
    -webkit-transform: translateZ(20px) translateY(5px);
            transform: translateZ(20px) translateY(5px)
  }
  100% {
    -webkit-transform: translateZ(20px) translateY(0px);
            transform: translateZ(20px) translateY(0px)
  }
}

@-webkit-keyframes hovery-z10 {
  0% {
    -webkit-transform: translateZ(10px) translateY(0px);
            transform: translateZ(10px) translateY(0px)
  }
  50% {
    -webkit-transform: translateZ(10px) translateY(5px);
            transform: translateZ(10px) translateY(5px)
  }
  100% {
    -webkit-transform: translateZ(10px) translateY(0px);
            transform: translateZ(10px) translateY(0px)
  }
}

@keyframes hovery-z10 {
  0% {
    -webkit-transform: translateZ(10px) translateY(0px);
            transform: translateZ(10px) translateY(0px)
  }
  50% {
    -webkit-transform: translateZ(10px) translateY(5px);
            transform: translateZ(10px) translateY(5px)
  }
  100% {
    -webkit-transform: translateZ(10px) translateY(0px);
            transform: translateZ(10px) translateY(0px)
  }
}

@-webkit-keyframes linear-wipe {
  from {
    white-space: nowrap;

    overflow: hidden;
    opacity: 0;
    width: 0;
  }
}

@keyframes linear-wipe {
  from {
    white-space: nowrap;

    overflow: hidden;
    opacity: 0;
    width: 0;
  }
}

@-webkit-keyframes stretchx-zn30 {
  0% {
    -webkit-transform: translateZ(-30px) scale(0.5, 1);
            transform: translateZ(-30px) scale(0.5, 1);
  }
  50% {
    -webkit-transform: translateZ(-30px) scale(1, 1);
            transform: translateZ(-30px) scale(1, 1);
  }
  100% {
    -webkit-transform: translateZ(-30px) scale(0.5, 1);
            transform: translateZ(-30px) scale(0.5, 1);
  }
}

@keyframes stretchx-zn30 {
  0% {
    -webkit-transform: translateZ(-30px) scale(0.5, 1);
            transform: translateZ(-30px) scale(0.5, 1);
  }
  50% {
    -webkit-transform: translateZ(-30px) scale(1, 1);
            transform: translateZ(-30px) scale(1, 1);
  }
  100% {
    -webkit-transform: translateZ(-30px) scale(0.5, 1);
            transform: translateZ(-30px) scale(0.5, 1);
  }
}
header.MuiPaper-elevation4.MuiPaper-root {
    box-shadow: none !important;
}

.MuiPaper-root:not(.MuiAppBar-root) {
    background-color: #020C17 !important;
}

li.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root {
    font-size: 1.4rem !important;
}

.MuiAppBar-positionFixed {
    right: 5px !important;
}

span.MuiChip-label {
    font-size: 1.2rem;
}
