@keyframes hide {
  from {
    opacity: 0;
  }
  to {
    opacity: 0;
  }
}

@keyframes enter-left {
  from {
    transform: translateX(-100px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes enter-forwards {
  from {
    transform: translateZ(-100px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes enter-up {
  from {
    transform: translateY(100px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes enter-left-z30 {
  from {
    transform: translateZ(30px) translateX(-100px);
    opacity: 0;
  }
  to {
    transform: translateZ(30px) translateX(0);
    opacity: 1;
  }
}

@keyframes enter-left-zn30 {
  from {
    transform: translateZ(-30px) translateX(-100px);
    opacity: 0;
  }
  to {
    transform: translateZ(-30px) translateX(0);
    opacity: 1;
  }
}

@keyframes enter-right-zn30 {
  from {
    transform: translateZ(-30px) translateX(100px);
    opacity: 0;
  }
  to {
    transform: translateZ(-30px) translateX(0);
    opacity: 1;
  }
}

@keyframes rotatez {
  from {
    transform: translateZ(0px) rotate(0deg);
  }
  to {
    transform: translateZ(0px) rotate(-360deg);
  }
}

@keyframes rotatez-full-z30 {
  from {
    transform: translateZ(30px) rotate(0deg);
  }
  to {
    transform: translateZ(30px) rotate(360deg);
  }
}

@keyframes rotatez-zn30 {
  from {
    transform: translateZ(-30px) rotate(0deg);
  }
  to {
    transform: translateZ(-30px) rotate(360deg);
  }
}

@keyframes enter-right {
  from {
    transform: translateX(100px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-up-z0 {
  from {
    transform: translateZ(0px) translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateZ(0px) translateY(0px);
    opacity: 1;
  }
}

@keyframes slide-up-zn10 {
  from {
    transform: translateZ(-10px) translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateZ(-10px) translateY(0px);
    opacity: 1;
  }
}

@keyframes slide-down-z30 {
  from {
    transform: translateZ(30px) translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateZ(30px) translateY(0px);
    opacity: 1;
  }
}

@keyframes slide-down-z20 {
  from {
    transform: translateZ(20px) translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateZ(20px) translateY(0px);
    opacity: 1;
  }
}

@keyframes slide-up-zn40 {
  from {
    transform: translateZ(-40px) translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateZ(-40px) translateY(0px);
    opacity: 1;
  }
}

@keyframes slide-down-z10 {
  from {
    transform: translateZ(10px) translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateZ(10px) translateY(0px);
    opacity: 1;
  }
}

@keyframes slide-left-z50 {
  from {
    transform: translateZ(50px) translateX(50px);
    opacity: 0;
  }
  to {
    transform: translateZ(50px) translateX(0px);
    opacity: 1;
  }
}

@keyframes slide-right-z50 {
  from {
    transform: translateZ(50px) translateX(-50px);
    opacity: 0;
  }
  to {
    transform: translateZ(50px) translateX(0px);
    opacity: 1;
  }
}

@keyframes hide-50-z40-rotate1deg {
  0% {
    transform: translateZ(40px) rotate(0deg);
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  51% {
    opacity: 0;
  }
  100% {
    transform: translateZ(40px) rotate(-1deg);
    opacity: 0;
  }
}

@keyframes hide-50 {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  51% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes hide-50-z20-rotate1deg {
  0% {
    transform: translateZ(20px) rotate(0deg);
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  51% {
    opacity: 0;
  }
  100% {
    transform: translateZ(20px) rotate(-1deg);
    opacity: 0;
  }
}

@keyframes slide-forwards-zn30 {
  from {
    transform: translateZ(-200px);
    opacity: 0;
  }
  to {
    transform: translateZ(-30px);
    opacity: 1;
  }
}

@keyframes slide-forwards-zn10 {
  from {
    transform: translateZ(-200px);
    opacity: 0;
  }
  to {
    transform: translateZ(-10px);
    opacity: 1;
  }
}

@keyframes slide-forwards-z10 {
  from {
    transform: translateZ(-200px);
    opacity: 0;
  }
  to {
    transform: translateZ(10px);
    opacity: 1;
  }
}

@keyframes slide-forwards-z20 {
  from {
    transform: translateZ(-200px);
    opacity: 0;
  }
  to {
    transform: translateZ(20px);
    opacity: 1;
  }
}

@keyframes slide-forwards-zn10 {
  from {
    transform: translateZ(-200px);
    opacity: 0;
  }
  to {
    transform: translateZ(-10px);
    opacity: 1;
  }
}

@keyframes slide-forwards-zn40 {
  from {
    transform: translateZ(-200px);
    opacity: 0;
  }
  to {
    transform: translateZ(-40px);
    opacity: 1;
  }
}

@keyframes slide-forwards-zn5 {
  from {
    transform: translateZ(-200px);
    opacity: 0;
  }
  to {
    transform: translateZ(-5px);
    opacity: 1;
  }
}

@keyframes slide-forwards-zn20 {
  from {
    transform: translateZ(-200px);
    opacity: 0;
  }
  to {
    transform: translateZ(-20px);
    opacity: 1;
  }
}

@keyframes slide-forwards-zn50 {
  from {
    transform: translateZ(-200px);
    opacity: 0;
  }
  to {
    transform: translateZ(-50px);
    opacity: 1;
  }
}

@keyframes slide-forwards-z30 {
  from {
    transform: translateZ(-200px);
    opacity: 0;
  }
  to {
    transform: translateZ(30px);
    opacity: 1;
  }
}

@keyframes slide-backwards-z50 {
  from {
    transform: translateZ(200px);
    opacity: 0;
  }
  to {
    transform: translateZ(50px);
    opacity: 1;
  }
}

@keyframes slide-backwards-zn30 {
  from {
    transform: translateZ(100px);
    opacity: 0;
  }
  to {
    transform: translateZ(-30px);
    opacity: 1;
  }
}

@keyframes slide-backwards-z30 {
  from {
    transform: translateZ(100px);
    opacity: 0;
  }
  to {
    transform: translateZ(30px);
    opacity: 1;
  }
}

@keyframes hoverx-left-z50 {
  0% {
    transform: translateZ(50px) translateX(0px);
  }
  50% {
    transform: translateZ(50px) translateX(-10px);
  }
  100% {
    transform: translateZ(50px) translateX(0px);
  }
}

@keyframes hoverx-right-z50 {
  0% {
    transform: translateZ(50px) translateX(0px);
  }
  50% {
    transform: translateZ(50px) translateX(10px);
  }
  100% {
    transform: translateZ(50px) translateX(0px);
  }
}

@keyframes hoverz-forwards-zn30 {
  0% {
    transform: translateZ(-30px);
  }
  50% {
    transform: translateZ(-45px);
  }
  100% {
    transform: translateZ(-30px);
  }
}

@keyframes hoverz-backwards-zn30 {
  0% {
    transform: translateZ(-30px);
  }
  50% {
    transform: translateZ(-15px);
  }
  100% {
    transform: translateZ(-30px);
  }
}

@keyframes hoverz-backwards-zn20 {
  0% {
    transform: translateZ(-20px);
  }
  50% {
    transform: translateZ(-10px);
  }
  100% {
    transform: translateZ(-20px);
  }
}

@keyframes hoverz-backwards-z30 {
  0% {
    transform: translateZ(30px);
  }
  50% {
    transform: translateZ(45px);
  }
  100% {
    transform: translateZ(30px);
  }
}

@keyframes hoverz-forwards-z30 {
  0% {
    transform: translateZ(30px);
  }
  50% {
    transform: translateZ(15px);
  }
  100% {
    transform: translateZ(30px);
  }
}

@keyframes hoverz-forwardslight-z30 {
  0% {
    transform: translateZ(30px);
  }
  50% {
    transform: translateZ(35px);
  }
  100% {
    transform: translateZ(30px);
  }
}

@keyframes hoverz-backwards-z50 {
  0% {
    transform: translateZ(50px);
  }
  50% {
    transform: translateZ(-5px);
  }
  100% {
    transform: translateZ(50px);
  }
}

@keyframes hoverz-forwards-z50 {
  0% {
    transform: translateZ(50px);
  }
  50% {
    transform: translateZ(60px);
  }
  100% {
    transform: translateZ(50px);
  }
}

@keyframes hoverz-forwards-zn40 {
  0% {
    transform: translateZ(-40px);
  }
  50% {
    transform: translateZ(-30px);
  }
  100% {
    transform: translateZ(-40px);
  }
}


@keyframes hoverz-forwards-zn10 {
  0% {
    transform: translateZ(-10px);
  }
  50% {
    transform: translateZ(-20px);
  }
  100% {
    transform: translateZ(-10px);
  }
}

@keyframes hoverz-forwards-z20 {
  0% {
    transform: translateZ(20px);
  }
  50% {
    transform: translateZ(30px);
  }
  100% {
    transform: translateZ(20px);
  }
}

@keyframes flash-25 {
  0% {
    opacity: 0;
  }
  35% {
    opacity: 0;
  }
  36% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  45% {
    opacity: 1;
  }
  46% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes hoverz-forwards-z30 {
  0% {
    transform: translateZ(30px);
  }
  50% {
    transform: translateZ(0px);
  }
  100% {
    transform: translateZ(30px);
  }
}

@keyframes hoverz-forwards-z10 {
  0% {
    transform: translateZ(10px);
  }
  50% {
    transform: translateZ(20px);
  }
  100% {
    transform: translateZ(10px);
  }
}

@keyframes rotatez-z30 {
  0% {
    transform: translateZ(30px) rotate(0deg)
  }
  25% {
    transform: translateZ(30px) rotate(1deg)
  }
  50% {
    transform: translateZ(30px) rotate(0deg)
  }
  75% {
    transform: translateZ(30px) rotate(-1deg)
  }
  100% {
    transform: translateZ(30px) rotate(0deg)
  }
}

@keyframes hovery-zn40 {
  0% {
    transform: translateZ(-40px) translateY(0px)
  }
  50% {
    transform: translateZ(-40px) translateY(3px)
  }
  100% {
    transform: translateZ(-40px) translateY(0px)
  }
}

@keyframes hovery-z20 {
  0% {
    transform: translateZ(20px) translateY(0px)
  }
  50% {
    transform: translateZ(20px) translateY(5px)
  }
  100% {
    transform: translateZ(20px) translateY(0px)
  }
}

@keyframes hovery-z10 {
  0% {
    transform: translateZ(10px) translateY(0px)
  }
  50% {
    transform: translateZ(10px) translateY(5px)
  }
  100% {
    transform: translateZ(10px) translateY(0px)
  }
}

@keyframes linear-wipe {
  from {
    white-space: nowrap;

    overflow: hidden;
    opacity: 0;
    width: 0;
  }
}

@keyframes stretchx-zn30 {
  0% {
    transform: translateZ(-30px) scale(0.5, 1);
  }
  50% {
    transform: translateZ(-30px) scale(1, 1);
  }
  100% {
    transform: translateZ(-30px) scale(0.5, 1);
  }
}